import React from "react";
import ExamDetails from "./ExamDetails";
import StartExamButton from "./StartExamButton";

const ExamInfo = ({ examData, isStarted, handleStartExam, examResult }) => {
  return (
    <div className="py-5 mt-[2.25rem]">
      <ExamDetails examData={examData} isStarted={isStarted}/>\
      <StartExamButton
        isStarted={isStarted}
        examResult={examResult}
        handleStartExam={handleStartExam}
      />
    </div>
  );
};

export default ExamInfo;
