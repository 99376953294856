import * as React from "react";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "./NavbarComponent";
import HeroWrapComponent from "./HelloWorldComponent";
import FooterComponent from "./FooterComponent";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { getUserInfoById, updatePassword } from "../services/UserService";
import { toast } from "react-toastify";
import LoadingComponent from "./LoadingComponent";

const AccountInfo = (props) => {
  const jwt = localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt"))
    : "";
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getUserInfoById();
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          const { data } = response.data;
          setUser(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetAvtRanking = () => {
    if (user?.level?.rank === "Đồng") {
      return (
        <img
          src="/images/dong.png"
          alt="User Avatar"
          className="profile-avatar"
        />
      );
    }

    if (user?.level?.rank === "Bạc") {
      return (
        <img
          src="/images/bac.png"
          alt="User Avatar"
          className="profile-avatar"
        />
      );
    }

    if (user?.level?.rank === "Vàng") {
      return (
        <img
          src="/images/vang.png"
          alt="User Avatar"
          className="profile-avatar"
        />
      );
    }

    if (user?.level?.rank === "Bạch kim") {
      return (
        <img
          src="/images/bachkim.png"
          alt="User Avatar"
          className="profile-avatar"
        />
      );
    }

    if (user?.level?.rank === "Kim cương") {
      return (
        <img
          src="/images/kimcuong.png"
          alt="User Avatar"
          className="profile-avatar"
        />
      );
    }

    if (user?.level?.rank === "Tinh anh") {
      return (
        <img
          src="/images/tinhanh.png"
          alt="User Avatar"
          className="profile-avatar"
        />
      );
    }

    if (user?.level?.rank === "Cao thủ") {
      return (
        <img
          src="/images/caothu.png"
          alt="User Avatar"
          className="profile-avatar"
        />
      );
    }

    if (user?.level?.rank === "Thách đấu") {
      return (
        <img
          src="/images/thachdau.png"
          alt="User Avatar"
          className="profile-avatar"
        />
      );
    }

    if (user?.level?.rank === "Chiến tướng") {
      return (
        <img
          src="/images/chientuong.png"
          alt="User Avatar"
          className="profile-avatar"
        />
      );
    }
  };

  const hanldUpdatePassword = async () => {
    if (!password) {
      toast.error("Vui lòng nhập mật khẩu");
      return;
    }

    try {
      setLoading(true);
      const res = await updatePassword({ password });
      if (res && res.data && res.data.message && res.status === 200) {
        toast.success(res.data.message);
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <React.Fragment>
      <div className="max-w-[1140px] m-auto">
        <NavbarComponent />
        <HeroWrapComponent />
      </div>
      <div className="profile-card m-auto mt-20 mb-20">
        <div className="profile-header">
          {handleSetAvtRanking()}
          <h1 className="profile-name">{user?.username}</h1>
          <p className="profile-title text-white">Học Sinh</p>
        </div>
        <div className="profile-info">
          <p>
            <strong>
              User ID:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </strong>{" "}
            {user?._id}
          </p>
          <p>
            <strong>
              Email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </strong>{" "}
            {user?.email}
          </p>
          <p>
            <strong>Username:&nbsp;</strong> {user?.username}
          </p>
          <p>
            <strong>
              Vip:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </strong>
            <span className="text-red-700 font-bold">
              {JSON.stringify(user?.vip)?.toLocaleUpperCase()}
            
            </span>
          </p>
          <p>
            <strong>Cấp độ:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{" "}
            {user?.level?.rank}
          </p>
        </div>
        <div className="profile-footer">
          <button className="btn-pi" onClick={handleClickOpen}>
            Đổi mật khẩu
          </button>
          <button className="btn-pi">Facebook</button>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Đổi mật khẩu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Nhập mật khẩu mới và sau đó bấm nút Subscribe
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            name="password"
            label="Mật khẩu mới *"
            type="password"
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={hanldUpdatePassword}>Subscribe</Button>
        </DialogActions>
      </Dialog>
      <FooterComponent />
    </React.Fragment>
  );
};

export default AccountInfo;
