import axios from "axios";
import jwtDecode from "jwt-decode";

// const API_URL_AUTH = "http://localhost:4000/api/auth";
const API_URL_AUTH = "https://pimath-server-edu.vercel.app/api/auth";

const register = async (body) => {
  return await axios.post(API_URL_AUTH + "/signup", body, {
    withCredentials: true, // Cho phép gửi cookie trong request
  });
};

const login = async (body) => {
  return await axios.post(API_URL_AUTH + "/login", body, {
    withCredentials: true, // Cho phép gửi cookie trong request
  });
};

const authHeader = () => {
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  if (jwt && jwt.token) return { Authorization: `Bearer ${jwt.token}` };
  return {};
};

const checkJwtExistsAndExpired = () => {
  const jwt = localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt"))
    : "";
  if (!jwt || !jwt.token) {
    localStorage.removeItem("jwt");
    return false; // Nếu không có token, coi như không hợp lệ
  }

  try {
    // Giải mã token và trích xuất trường exp
    const { exp } = jwtDecode(jwt.token);
    if (!exp) {
      localStorage.removeItem("jwt"); // Xóa tất cả thông tin trong localStorage
      return false; // Nếu không có trường exp, coi như không hợp lệ
    }

    // Lấy thời gian hiện tại tính bằng giây kể từ Unix Epoch
    const now = Date.now() / 1000;

    if (exp < now) {
      localStorage.removeItem("jwt"); // Xóa tất cả thông tin trong localStorage
      return false; // Token đã hết hạn
    }

    return true; // Token còn hiệu lực
  } catch (error) {
    localStorage.removeItem("jwt"); // Xóa tất cả thông tin trong localStorage nếu có lỗi khi giải mã token
    return false; // Nếu có lỗi khi giải mã token, coi như không hợp lệ
  }
};
export { register, login, authHeader, checkJwtExistsAndExpired };
