import axios from "axios";
import { authHeader } from "./AuthService";

// const QUESTIONBANK_URL_AUTH = "http://localhost:4000/api/question-bank-test";
const QUESTIONBANK_URL_AUTH =
  "https://pimath-server-edu.vercel.app/api/question-bank-test";

const handleQuestionBank = async (data) => {
  return await axios.post(QUESTIONBANK_URL_AUTH, data, {
    headers: authHeader(),
  });
};

const checkCountUserQuestionBank = async () => {
  return await axios.get(QUESTIONBANK_URL_AUTH + `/user`, {
    headers: authHeader(),
  });
};

export { handleQuestionBank, checkCountUserQuestionBank };
