import heroSec from './hero-section.svg';
import Timeline from './timeline.svg';
import gallery from './gallery.svg';
import galleryNew from './gallerynew.svg';
import globe from './globe.svg';
import mousetrail from './mousetrail.svg';
import image_mousetrail from './image_mousetrail.svg';
import image_masking from './image_masking.svg';
import responsive_drawer from './responsive.svg';
import sticky from './sticky.svg';
import horizontascroll from './horizontal-scrolling.svg';
import modal from './modal.svg';
import imagereveal from './image-reveal.svg';
import stackingcard from './stacking card.svg';
import card from './card.svg';
import tabs from './tabs.svg';
import grid from './grid.svg';
import buttons from './buttons.svg';

import faqs from './faqs.svg';
import carousel from './carousel.svg';
import motionNumber from './motion-number.svg';
import clippath from './clip-path.svg';
import dragitems from './drag-items.svg';
import sparkles from './sparkles.svg';
import password from './password.svg';
import youtubeTags from './youtube-tags.svg';
import coffee from './coffee.svg';
import ripple from './ripple.png';
const preview = {
  horizontascroll,
  ripple,
  motionNumber,
  heroSec,
  stackingcard,
  card,
  imagereveal,
  password,
  sparkles,
  dragitems,
  modal,
  Timeline,
  gallery,
  galleryNew,
  globe,
  mousetrail,
  faqs,
  buttons,
  grid,
  tabs,
  sticky,
  responsive_drawer,
  image_masking,
  image_mousetrail,
  carousel,
  clippath,
  youtubeTags,
  coffee,
};
export default preview;
