import { useEffect, useState } from "react";
import preview from "../common/assets/preview";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogImage,
  DialogClose,
  DialogDescription,
  DialogContainer,
} from "./LinearDialog.js";
import { Plus, ChevronsRight } from "lucide-react";
import QuizBox from "./QuizBox.js";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Checkbox,
  ListItemText,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { checkCountUserQuestionBank } from "../services/QuestionBankService.js";
import { toast } from "react-toastify";
const items = [
  {
    id: 1,
    url: preview.galleryNew,
    title: "Thực chiến THPT QG",
    type: "THPT",
    description:
      "Kỳ thi trung học phổ thông quốc gia (viết tắt là THPTQG). Gộp lại từ hai kỳ thi là Kỳ thi tốt nghiệp trung học phổ thông và Kỳ thi tuyển sinh đại học và cao đẳng, kỳ thi này xét cho thí sinh hai nguyện vọng: tốt nghiệp trung học phổ thông và tuyển sinh đại học, cao đẳng. Để tham dự kỳ thi này, thí sinh phải thi ít nhất 4 bài thi gồm 3 bài thi độc lập bắt buộc là Toán, Ngữ văn, Ngoại ngữ đối với học sinh THPT, 2 bài thi độc lập bắt buộc là Toán, Ngữ văn đối với học sinh GDTX và một bài thi tổ hợp: Khoa học tự nhiên (Vật lý, Hóa học, Sinh học) và Khoa học xã hội (Lịch sử, Địa lý, Giáo dục công dân)",
    data: [
      { label: "Tất cả các chuyên đề", key: "Tatca" },
      { label: "Tính đơn điệu của hàm số", key: "Hamso_Dondieu" },
      { label: "Cực trị của hàm số", key: "Hamso_Cuctri" },
      { label: "Giá trị lớn nhất và nhỏ nhất của hàm số", key: "Hamso_Minmax" },
      { label: "Tiệm cận của đồ thị hàm số", key: "Hamso_Tiemcan" },
      { label: "Tương giao của đồ thị hàm số", key: "Hamso_Tuonggiao" },
      { label: "Tiếp tuyến của đồ thị hàm số", key: "Hamso_Tieptuyen" },
      {
        label: "Ứng dụng đạo hàm để giải bài toán thực tế",
        key: "Hamso_Toanthucte",
      },
      { label: "Vector và hệ tọa độ trong không gian", key: "Vector_Hetoado" },
      {
        label: "Ứng dụng vector để giải bài toán thực tế",
        key: "Vector_Toanthucte",
      },
      {
        label: "Khoảng biến thiên – Khoảng tứ phân vị của MSLGN",
        key: "Mausolieughepnhom_Khoangbienthien_Khoangtuphanvi",
      },
      {
        label: "Phương sai & độ lệch chuẩn của MSLGN",
        key: "Mausolieughepnhom_Phuongsai_Dolechchuan",
      },
      { label: "Nguyên hàm", key: "TichphanUngdung_Nguyeham" },
      { label: "Tích phân", key: "TichphanUngdung_Tichphan" },
      {
        label: "Ứng dụng tích phân (diện tích và thể tích)",
        key: "TichphanUngdung_Dientich_Thetich",
      },
      {
        label: "Ứng dụng tích phân giải bài toán thực tế",
        key: "TichphanUngdung_Toanthucte",
      },
      { label: "Hệ tọa độ trong Oxyz", key: "OXYZ_Hetoado" },
      {
        label: "Phương trình đường thẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhduongthang",
      },
      {
        label: "Phương trình mặt phẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhmatphang",
      },
      {
        label: "Phương trình mặt cầu trong Oxyz",
        key: "OXYZ_Phuongtrinhmatcau",
      },
      { label: "Cực trị trong Oxyz", key: "OXYZ_Cuctri" },
      {
        label: "Ứng dụng Oxyz để giải bài toán thực tế",
        key: "OXYZ_Toanthucte",
      },
      {
        label: "Xác suất có điều kiện",
        key: "Xacsuatcodieukien_Xacsuatcodieukien",
      },
      {
        label: "Công thức xác suất toàn phần và Bayes",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bayes",
      },
      {
        label: "Công thức xác suất Bernoulli",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bernoulli",
      },
      {
        label: "Quy tắc cộng, Quy tắc nhân",
        key: "Xacsuat_Quytaccong_Quytacnhan",
      },
      {
        label: "Hoán vị - Tổ hợp - Chỉnh hợp",
        key: "Xacsuat_HoanVi_Tohop_ChinhHop",
      },
      {
        label: "Biến cố và định nghĩa cổ điển của xác suất",
        key: "Xacsuat_Bienco_Dinhnghiaxacsuat",
      },
      {
        label: "Biến cố hợp, biến cố giao, biến cố độc lập",
        key: "Xacsuat_Biencohop_Biencogiao_Biencodoclap",
      },
      { label: "Công thức cộng xác suất", key: "Xacsuat_Congthuccong" },
      {
        label: "Công thức nhân xác suất cho hai biến cố độc lập",
        key: "Xacsuat_Congthucnhan",
      },
      { label: "Góc trong không gian", key: "Hinhhockhonggian_Goc" },
      {
        label: "Khoảng cách trong không gian",
        key: "Hinhhockhonggian_Khoangcach",
      },
      { label: "Thể tích khối đa diện", key: "Hinhhockhonggian_Thetich" },
      { label: "Tỉ số thể tích", key: "Hinhhockhonggian_Tisothetich" },
      { label: "Cực trị hình học không gian", key: "Hinhhockhonggian_Cuctri" },
      {
        label: "Ứng dụng hình học không gian để giải bài toán thực tế",
        key: "Hinhhockhonggian_Toanthucte",
      },
      {
        label: "Bài toán thực tế liên quan đến hình trụ, nón, cầu",
        key: "TruNonCau_Toanthucte",
      },
      {
        label:
          "Bài toán thực tế liên quan đến bất phương trình và hệ bất phương trình",
        key: "Batphuongtrinh_HeBatphuongtrinh_Toanthucte",
      },
    ],
  },
  {
    id: 2,
    url: preview.globe,
    title: "Thực chiến ĐGTD (TSA)",
    type: "TSA",
    description: `Kỳ thi Đánh giá tư duy (Thinking Skills Assessment - TSA) do ĐH Bách Khoa HN tổ chức với mục tiêu của Bài thi TSA là đánh giá ba năng lực tư duy nền tảng của học sinh, bao gồm: Tư duy Toán học, Tư duy Đọc hiểu và Tư duy Khoa học/Giải quyết vấn đề - những yếu tố quan trọng giúp học sinh có thể học tập tốt nhất tại môi trường giáo dục đại học. Tổng thời gian của Bài thi là 150 phút, bao gồm 100 câu hỏi trắc nghiệm cho 03 phần thi. Phần thi Tư duy Toán học có thời lượng là 60 phút, phân thi Tư duy Đọc hiểu có thời lượng là 30 phút và phần thi Tư duy Khoa học/Giải quyết vấn đề có thời lượng là 60 phút`,
    data: [
      { label: "Tất cả các chuyên đề", key: "Tatca" },
      { label: "Tính đơn điệu của hàm số", key: "Hamso_Dondieu" },
      { label: "Cực trị của hàm số", key: "Hamso_Cuctri" },
      { label: "Giá trị lớn nhất và nhỏ nhất của hàm số", key: "Hamso_Minmax" },
      { label: "Tiệm cận của đồ thị hàm số", key: "Hamso_Tiemcan" },
      { label: "Tương giao của đồ thị hàm số", key: "Hamso_Tuonggiao" },
      { label: "Tiếp tuyến của đồ thị hàm số", key: "Hamso_Tieptuyen" },
      {
        label: "Ứng dụng đạo hàm để giải bài toán thực tế",
        key: "Hamso_Toanthucte",
      },
      { label: "Vector và hệ tọa độ trong không gian", key: "Vector_Hetoado" },
      {
        label: "Ứng dụng vector để giải bài toán thực tế",
        key: "Vector_Toanthucte",
      },
      {
        label: "Khoảng biến thiên – Khoảng tứ phân vị của MSLGN",
        key: "Mausolieughepnhom_Khoangbienthien_Khoangtuphanvi",
      },
      {
        label: "Phương sai & độ lệch chuẩn của MSLGN",
        key: "Mausolieughepnhom_Phuongsai_Dolechchuan",
      },
      { label: "Nguyên hàm", key: "TichphanUngdung_Nguyeham" },
      { label: "Tích phân", key: "TichphanUngdung_Tichphan" },
      {
        label: "Ứng dụng tích phân (diện tích và thể tích)",
        key: "TichphanUngdung_Dientich_Thetich",
      },
      {
        label: "Ứng dụng tích phân giải bài toán thực tế",
        key: "TichphanUngdung_Toanthucte",
      },
      { label: "Hệ tọa độ trong Oxyz", key: "OXYZ_Hetoado" },
      {
        label: "Phương trình đường thẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhduongthang",
      },
      {
        label: "Phương trình mặt phẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhmatphang",
      },
      {
        label: "Phương trình mặt cầu trong Oxyz",
        key: "OXYZ_Phuongtrinhmatcau",
      },
      { label: "Cực trị trong Oxyz", key: "OXYZ_Cuctri" },
      {
        label: "Ứng dụng Oxyz để giải bài toán thực tế",
        key: "OXYZ_Toanthucte",
      },
      {
        label: "Xác suất có điều kiện",
        key: "Xacsuatcodieukien_Xacsuatcodieukien",
      },
      {
        label: "Công thức xác suất toàn phần và Bayes",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bayes",
      },
      {
        label: "Công thức xác suất Bernoulli",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bernoulli",
      },
      {
        label: "Giá trị lượng giác của góc lượng giác",
        key: "Luonggiac_Gocluonggiac",
      },
      { label: "Công thức lượng giác", key: "Luonggiac_Congthucluonggiac" },
      { label: "Hàm số lượng giác", key: "Luonggiac_Hamsoluonggiac" },
      {
        label: "Phương trình lượng giác",
        key: "Luonggiac_Phuongtrinhluonggiac",
      },
      {
        label: "Ứng dụng lượng giác để giải bài toán thực tế",
        key: "Luonggiac_Toanthucte",
      },
      {
        label: "Quy tắc cộng, Quy tắc nhân",
        key: "Xacsuat_Quytaccong_Quytacnhan",
      },
      {
        label: "Hoán vị - Tổ hợp - Chỉnh hợp",
        key: "Xacsuat_HoanVi_Tohop_ChinhHop",
      },
      {
        label: "Biến cố và định nghĩa cổ điển của xác suất",
        key: "Xacsuat_Bienco_Dinhnghiaxacsuat",
      },
      {
        label: "Biến cố hợp, biến cố giao, biến cố độc lập",
        key: "Xacsuat_Biencohop_Biencogiao_Biencodoclap",
      },
      { label: "Công thức cộng xác suất", key: "Xacsuat_Congthuccong" },
      {
        label: "Công thức nhân xác suất cho hai biến cố độc lập",
        key: "Xacsuat_Congthucnhan",
      },
      { label: "Nhị thức Newton", key: "Xacsuat_Nhithucnewton" },
      { label: "Dãy số", key: "Dayso_Dayso" },
      { label: "Cấp số nhân", key: "Dayso_Capsonhan" },
      { label: "Cấp sô cộng", key: "Dayso_Capsocong" },
      {
        label: "Ứng dụng dãy số để giải bài toán thực tế",
        key: "Dayso_Toanthucte",
      },
      { label: "Giới hạn của dãy số", key: "Gioihan_Gioihandayso" },
      { label: "Giới hạn của hàm số", key: "Gioihan_Gioihanhamso" },
      { label: "Hàm số liên tục", key: "Gioihan_Hamsolientuc" },
      {
        label: "Ứng dụng giới hạn để giải bài toán thực tế",
        key: "Gioihan_Toanthucte",
      },
      { label: "Góc trong không gian", key: "Hinhhockhonggian_Goc" },
      {
        label: "Khoảng cách trong không gian",
        key: "Hinhhockhonggian_Khoangcach",
      },
      { label: "Thể tích khối đa diện", key: "Hinhhockhonggian_Thetich" },
      { label: "Tỉ số thể tích", key: "Hinhhockhonggian_Tisothetich" },
      {
        label: "Tỉ số, thiết diện, quan hệ song song, quan hệ vuông góc",
        key: "Hinhhockhonggian_Tiso_Thietdien_Quanhesongsong_Quanhevuongoc",
      },
      { label: "Cực trị hình học không gian", key: "Hinhhockhonggian_Cuctri" },
      {
        label: "Ứng dụng hình học không gian để giải bài toán thực tế",
        key: "Hinhhockhonggian_Toanthucte",
      },
      {
        label: "Lũy thừa và hàm số lũy thừa trong mũ - logarit",
        key: "MuLogarit_Luythua_Hamsoluythua",
      },
      {
        label: "Phương trình, bất phương trình logarit",
        key: "MuLogarit_Phuongtrinh_Batphuongtrinh_Logarit",
      },
      {
        label: "Phương trình, bất phương trình mũ",
        key: "MuLogarit_Phuongtrinh_Batphuongtrinh_Mu",
      },
      {
        label: "Bài toán nghiệm nguyên trong trong mũ - logarit",
        key: "MuLogarit_Nghiemnguyen",
      },
      {
        label: "Bài toán chứa tham số trong trong mũ - logarit",
        key: "MuLogarit_Chuathamso",
      },
      { label: "Cực trị trong trong mũ - logarit", key: "MuLogarit_Minmax" },
      {
        label: "Ứng dụng mũ logarit để giải bài toán thực tế",
        key: "MuLogarit_Toanthucte",
      },
      { label: "Đạo hàm", key: "DaoHam" },
      {
        label: "Hình trụ, Hinh nón, Hình cầu",
        key: "TruNonCau_Tru_Non_Cau",
      },
      {
        label: "Bài toán thực tế liên quan đến hình trụ, nón, cầu",
        key: "TruNonCau_Toanthucte",
      },
      { label: "Tập hợp", key: "Taphop" },
      { label: "Mệnh đề", key: "Menhde" },
      {
        label:
          "Bài toán thực tế liên quan đến bất phương trình và hệ bất phương trình",
        key: "Batphuongtrinh_HeBatphuongtrinh_Toanthucte",
      },
      { label: "Hệ thức lượng trong tam giác", key: "Hethucluongtrongtamgiac" },
      {
        label: "Bài toán thực tế liên quan đến Hệ thức lượng trong tam giác",
        key: "Hethucluongtrongtamgiac_Toanthucte",
      },
      { label: "Vector", key: "Vector" },
      { label: "Hàm số, đồ thị và ứng dụng", key: "Hamso_Dothi_Ungdung" },
      { label: "Phương trình đường thẳng", key: "Oxy_Phuongtrinhduongthang" },
      { label: "Phương trình đường tròn", key: "Oxy_Phuongtrinhduongtron" },
      { label: "Ba đường conic", key: "Oxy_Baduongconic" },
      { label: "Số học", key: "Sohoc" },
    ],
  },
  {
    id: 3,
    url: preview.mousetrail,
    title: "Thực chiến ĐGNL (HSA)",
    type: "HSA",
    description: `Bài thi đánh giá năng lực (HSA) do ĐH Quốc gia HN tổ chức gồm 3 phần với các câu hỏi trắc nghiệm khách quan (lựa chọn đáp án) và câu hỏi điền đáp án về các lĩnh vực Toán học, Văn học/Ngôn ngữ, Khoa học (lựa chọn) hoặc tiếng Anh. Với Tư duy định lượng Toán học 50 câu-75phú-50đ. Tư duy định tính Văn học - Ngôn ngữ 50câu-60phút-50đ. Khoa học hoặc Tiếng Anh 50câu-60phút-50đ`,
    data: [
      { label: "Tất cả các chuyên đề", key: "Tatca" },
      { label: "Tính đơn điệu của hàm số", key: "Hamso_Dondieu" },
      { label: "Cực trị của hàm số", key: "Hamso_Cuctri" },
      { label: "Giá trị lớn nhất và nhỏ nhất của hàm số", key: "Hamso_Minmax" },
      { label: "Tiệm cận của đồ thị hàm số", key: "Hamso_Tiemcan" },
      { label: "Tương giao của đồ thị hàm số", key: "Hamso_Tuonggiao" },
      { label: "Tiếp tuyến của đồ thị hàm số", key: "Hamso_Tieptuyen" },
      {
        label: "Ứng dụng đạo hàm để giải bài toán thực tế",
        key: "Hamso_Toanthucte",
      },
      { label: "Vector và hệ tọa độ trong không gian", key: "Vector_Hetoado" },
      {
        label: "Ứng dụng vector để giải bài toán thực tế",
        key: "Vector_Toanthucte",
      },
      {
        label: "Khoảng biến thiên – Khoảng tứ phân vị của MSLGN",
        key: "Mausolieughepnhom_Khoangbienthien_Khoangtuphanvi",
      },
      {
        label: "Phương sai & độ lệch chuẩn của MSLGN",
        key: "Mausolieughepnhom_Phuongsai_Dolechchuan",
      },
      { label: "Nguyên hàm", key: "TichphanUngdung_Nguyeham" },
      { label: "Tích phân", key: "TichphanUngdung_Tichphan" },
      {
        label: "Ứng dụng tích phân (diện tích và thể tích)",
        key: "TichphanUngdung_Dientich_Thetich",
      },
      {
        label: "Ứng dụng tích phân giải bài toán thực tế",
        key: "TichphanUngdung_Toanthucte",
      },
      { label: "Hệ tọa độ trong Oxyz", key: "OXYZ_Hetoado" },
      {
        label: "Phương trình đường thẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhduongthang",
      },
      {
        label: "Phương trình mặt phẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhmatphang",
      },
      {
        label: "Phương trình mặt cầu trong Oxyz",
        key: "OXYZ_Phuongtrinhmatcau",
      },
      { label: "Cực trị trong Oxyz", key: "OXYZ_Cuctri" },
      {
        label: "Ứng dụng Oxyz để giải bài toán thực tế",
        key: "OXYZ_Toanthucte",
      },
      {
        label: "Xác suất có điều kiện",
        key: "Xacsuatcodieukien_Xacsuatcodieukien",
      },
      {
        label: "Công thức xác suất toàn phần và Bayes",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bayes",
      },
      {
        label: "Công thức xác suất Bernoulli",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bernoulli",
      },
      {
        label: "Giá trị lượng giác của góc lượng giác",
        key: "Luonggiac_Gocluonggiac",
      },
      { label: "Công thức lượng giác", key: "Luonggiac_Congthucluonggiac" },
      { label: "Hàm số lượng giác", key: "Luonggiac_Hamsoluonggiac" },
      {
        label: "Phương trình lượng giác",
        key: "Luonggiac_Phuongtrinhluonggiac",
      },
      {
        label: "Ứng dụng lượng giác để giải bài toán thực tế",
        key: "Luonggiac_Toanthucte",
      },
      {
        label: "Quy tắc cộng, Quy tắc nhân",
        key: "Xacsuat_Quytaccong_Quytacnhan",
      },
      {
        label: "Hoán vị - Tổ hợp - Chỉnh hợp",
        key: "Xacsuat_HoanVi_Tohop_ChinhHop",
      },
      {
        label: "Biến cố và định nghĩa cổ điển của xác suất",
        key: "Xacsuat_Bienco_Dinhnghiaxacsuat",
      },
      {
        label: "Biến cố hợp, biến cố giao, biến cố độc lập",
        key: "Xacsuat_Biencohop_Biencogiao_Biencodoclap",
      },
      { label: "Công thức cộng xác suất", key: "Xacsuat_Congthuccong" },
      {
        label: "Công thức nhân xác suất cho hai biến cố độc lập",
        key: "Xacsuat_Congthucnhan",
      },
      { label: "Nhị thức Newton", key: "Xacsuat_Nhithucnewton" },
      { label: "Dãy số", key: "Dayso_Dayso" },
      { label: "Cấp số nhân", key: "Dayso_Capsonhan" },
      { label: "Cấp sô cộng", key: "Dayso_Capsocong" },
      {
        label: "Ứng dụng dãy số để giải bài toán thực tế",
        key: "Dayso_Toanthucte",
      },
      { label: "Giới hạn của dãy số", key: "Gioihan_Gioihandayso" },
      { label: "Giới hạn của hàm số", key: "Gioihan_Gioihanhamso" },
      { label: "Hàm số liên tục", key: "Gioihan_Hamsolientuc" },
      {
        label: "Ứng dụng giới hạn để giải bài toán thực tế",
        key: "Gioihan_Toanthucte",
      },
      { label: "Góc trong không gian", key: "Hinhhockhonggian_Goc" },
      {
        label: "Khoảng cách trong không gian",
        key: "Hinhhockhonggian_Khoangcach",
      },
      { label: "Thể tích khối đa diện", key: "Hinhhockhonggian_Thetich" },
      { label: "Tỉ số thể tích", key: "Hinhhockhonggian_Tisothetich" },
      {
        label: "Tỉ số, thiết diện, quan hệ song song, quan hệ vuông góc",
        key: "Hinhhockhonggian_Tiso_Thietdien_Quanhesongsong_Quanhevuongoc",
      },
      { label: "Cực trị hình học không gian", key: "Hinhhockhonggian_Cuctri" },
      {
        label: "Ứng dụng hình học không gian để giải bài toán thực tế",
        key: "Hinhhockhonggian_Toanthucte",
      },
      {
        label: "Lũy thừa và hàm số lũy thừa trong mũ - logarit",
        key: "MuLogarit_Luythua_Hamsoluythua",
      },
      {
        label: "Phương trình, bất phương trình logarit",
        key: "MuLogarit_Phuongtrinh_Batphuongtrinh_Logarit",
      },
      {
        label: "Phương trình, bất phương trình mũ",
        key: "MuLogarit_Phuongtrinh_Batphuongtrinh_Mu",
      },
      {
        label: "Bài toán nghiệm nguyên trong trong mũ - logarit",
        key: "MuLogarit_Nghiemnguyen",
      },
      {
        label: "Bài toán chứa tham số trong trong mũ - logarit",
        key: "MuLogarit_Chuathamso",
      },
      { label: "Cực trị trong trong mũ - logarit", key: "MuLogarit_Minmax" },
      {
        label: "Ứng dụng mũ logarit để giải bài toán thực tế",
        key: "MuLogarit_Toanthucte",
      },
      { label: "Đạo hàm", key: "DaoHam" },
      {
        label: "Hình trụ, Hinh nón, Hình cầu",
        key: "TruNonCau_Tru_Non_Cau",
      },
      {
        label: "Bài toán thực tế liên quan đến hình trụ, nón, cầu",
        key: "TruNonCau_Toanthucte",
      },
      {
        label:
          "Bài toán thực tế liên quan đến bất phương trình và hệ bất phương trình",
        key: "Batphuongtrinh_HeBatphuongtrinh_Toanthucte",
      },
      { label: "Hàm số, đồ thị và ứng dụng", key: "Hamso_Dothi_Ungdung" },
      { label: "Phương trình đường thẳng", key: "Oxy_Phuongtrinhduongthang" },
      { label: "Phương trình đường tròn", key: "Oxy_Phuongtrinhduongtron" },
      { label: "Ba đường conic", key: "Oxy_Baduongconic" },
    ],
  },
  {
    id: 3,
    url: preview.mousetrail,
    title: "Thực chiến ĐGNL (APT)",
    type: "APT",
    description: `Kỳ thi ĐGNL HCM (APT) là kỳ thi đánh giá năng lực do ĐHQG Hồ Chí Minh tổ chức dành cho học sinh THPT. Nhằm đánh giá năng lực cơ bản để học đại học của thí sinh như sử dụng ngôn ngữ; tư duy logic, sử dụng dữ liệu; giải quyết vấn đề. Sử dụng ngôn ngữ (40 câu): Tiếng Việt (20 câu) và Tiếng Anh (20 câu). Toán học, tư duy logic và phân tích số liệu (30 câu). Giải quyết vấn đề (50 câu): bao gồm các môn Hóa học, Vật lý, Sinh học, Địa lý, Lịch Sử, Giáo dục kinh tế và pháp luật. Thí sinh sẽ lựa chọn làm các câu hỏi thuộc 3 nhóm trong số 6 nhóm lĩnh vực`,
    data: [
      { label: "Tất cả các chuyên đề", key: "Tatca" },
      { label: "Tính đơn điệu của hàm số", key: "Hamso_Dondieu" },
      { label: "Cực trị của hàm số", key: "Hamso_Cuctri" },
      { label: "Giá trị lớn nhất và nhỏ nhất của hàm số", key: "Hamso_Minmax" },
      { label: "Tiệm cận của đồ thị hàm số", key: "Hamso_Tiemcan" },
      { label: "Tương giao của đồ thị hàm số", key: "Hamso_Tuonggiao" },
      { label: "Tiếp tuyến của đồ thị hàm số", key: "Hamso_Tieptuyen" },
      {
        label: "Ứng dụng đạo hàm để giải bài toán thực tế",
        key: "Hamso_Toanthucte",
      },
      { label: "Vector và hệ tọa độ trong không gian", key: "Vector_Hetoado" },
      {
        label: "Ứng dụng vector để giải bài toán thực tế",
        key: "Vector_Toanthucte",
      },
      {
        label: "Khoảng biến thiên – Khoảng tứ phân vị của MSLGN",
        key: "Mausolieughepnhom_Khoangbienthien_Khoangtuphanvi",
      },
      {
        label: "Phương sai & độ lệch chuẩn của MSLGN",
        key: "Mausolieughepnhom_Phuongsai_Dolechchuan",
      },
      { label: "Nguyên hàm", key: "TichphanUngdung_Nguyeham" },
      { label: "Tích phân", key: "TichphanUngdung_Tichphan" },
      {
        label: "Ứng dụng tích phân (diện tích và thể tích)",
        key: "TichphanUngdung_Dientich_Thetich",
      },
      {
        label: "Ứng dụng tích phân giải bài toán thực tế",
        key: "TichphanUngdung_Toanthucte",
      },
      { label: "Hệ tọa độ trong Oxyz", key: "OXYZ_Hetoado" },
      {
        label: "Phương trình đường thẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhduongthang",
      },
      {
        label: "Phương trình mặt phẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhmatphang",
      },
      {
        label: "Phương trình mặt cầu trong Oxyz",
        key: "OXYZ_Phuongtrinhmatcau",
      },
      { label: "Cực trị trong Oxyz", key: "OXYZ_Cuctri" },
      {
        label: "Ứng dụng Oxyz để giải bài toán thực tế",
        key: "OXYZ_Toanthucte",
      },
      {
        label: "Xác suất có điều kiện",
        key: "Xacsuatcodieukien_Xacsuatcodieukien",
      },
      {
        label: "Công thức xác suất toàn phần và Bayes",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bayes",
      },
      {
        label: "Công thức xác suất Bernoulli",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bernoulli",
      },
      {
        label: "Giá trị lượng giác của góc lượng giác",
        key: "Luonggiac_Gocluonggiac",
      },
      { label: "Công thức lượng giác", key: "Luonggiac_Congthucluonggiac" },
      { label: "Hàm số lượng giác", key: "Luonggiac_Hamsoluonggiac" },
      {
        label: "Phương trình lượng giác",
        key: "Luonggiac_Phuongtrinhluonggiac",
      },
      {
        label: "Ứng dụng lượng giác để giải bài toán thực tế",
        key: "Luonggiac_Toanthucte",
      },
      {
        label: "Quy tắc cộng, Quy tắc nhân",
        key: "Xacsuat_Quytaccong_Quytacnhan",
      },
      {
        label: "Hoán vị - Tổ hợp - Chỉnh hợp",
        key: "Xacsuat_HoanVi_Tohop_ChinhHop",
      },
      {
        label: "Biến cố và định nghĩa cổ điển của xác suất",
        key: "Xacsuat_Bienco_Dinhnghiaxacsuat",
      },
      {
        label: "Biến cố hợp, biến cố giao, biến cố độc lập",
        key: "Xacsuat_Biencohop_Biencogiao_Biencodoclap",
      },
      { label: "Công thức cộng xác suất", key: "Xacsuat_Congthuccong" },
      {
        label: "Công thức nhân xác suất cho hai biến cố độc lập",
        key: "Xacsuat_Congthucnhan",
      },
      { label: "Nhị thức Newton", key: "Xacsuat_Nhithucnewton" },
      { label: "Dãy số", key: "Dayso_Dayso" },
      { label: "Cấp số nhân", key: "Dayso_Capsonhan" },
      { label: "Cấp sô cộng", key: "Dayso_Capsocong" },
      {
        label: "Ứng dụng dãy số để giải bài toán thực tế",
        key: "Dayso_Toanthucte",
      },
      { label: "Giới hạn của dãy số", key: "Gioihan_Gioihandayso" },
      { label: "Giới hạn của hàm số", key: "Gioihan_Gioihanhamso" },
      { label: "Hàm số liên tục", key: "Gioihan_Hamsolientuc" },
      {
        label: "Ứng dụng giới hạn để giải bài toán thực tế",
        key: "Gioihan_Toanthucte",
      },
      { label: "Góc trong không gian", key: "Hinhhockhonggian_Goc" },
      {
        label: "Khoảng cách trong không gian",
        key: "Hinhhockhonggian_Khoangcach",
      },
      { label: "Thể tích khối đa diện", key: "Hinhhockhonggian_Thetich" },
      { label: "Tỉ số thể tích", key: "Hinhhockhonggian_Tisothetich" },
      {
        label: "Tỉ số, thiết diện, quan hệ song song, quan hệ vuông góc",
        key: "Hinhhockhonggian_Tiso_Thietdien_Quanhesongsong_Quanhevuongoc",
      },
      { label: "Cực trị hình học không gian", key: "Hinhhockhonggian_Cuctri" },
      {
        label: "Ứng dụng hình học không gian để giải bài toán thực tế",
        key: "Hinhhockhonggian_Toanthucte",
      },
      {
        label: "Lũy thừa và hàm số lũy thừa trong mũ - logarit",
        key: "MuLogarit_Luythua_Hamsoluythua",
      },
      {
        label: "Phương trình, bất phương trình logarit",
        key: "MuLogarit_Phuongtrinh_Batphuongtrinh_Logarit",
      },
      {
        label: "Phương trình, bất phương trình mũ",
        key: "MuLogarit_Phuongtrinh_Batphuongtrinh_Mu",
      },
      {
        label: "Bài toán nghiệm nguyên trong trong mũ - logarit",
        key: "MuLogarit_Nghiemnguyen",
      },
      {
        label: "Bài toán chứa tham số trong trong mũ - logarit",
        key: "MuLogarit_Chuathamso",
      },
      { label: "Cực trị trong trong mũ - logarit", key: "MuLogarit_Minmax" },
      {
        label: "Ứng dụng mũ logarit để giải bài toán thực tế",
        key: "MuLogarit_Toanthucte",
      },
      { label: "Đạo hàm", key: "DaoHam" },
      {
        label: "Hình trụ, Hinh nón, Hình cầu",
        key: "TruNonCau_Tru_Non_Cau",
      },
      {
        label: "Bài toán thực tế liên quan đến hình trụ, nón, cầu",
        key: "TruNonCau_Toanthucte",
      },
      {
        label:
          "Bài toán thực tế liên quan đến bất phương trình và hệ bất phương trình",
        key: "Batphuongtrinh_HeBatphuongtrinh_Toanthucte",
      },
      { label: "Hàm số, đồ thị và ứng dụng", key: "Hamso_Dothi_Ungdung" },
      { label: "Phương trình đường thẳng", key: "Oxy_Phuongtrinhduongthang" },
      { label: "Phương trình đường tròn", key: "Oxy_Phuongtrinhduongtron" },
      { label: "Ba đường conic", key: "Oxy_Baduongconic" },
    ],
  },
  {
    id: 3,
    url: preview.galleryNew,
    title: "Thực chiến HSG",
    type: "HSG",
    description: `Kỳ thi chọn học sinh giỏi tỉnh thành phố trung học phổ thông (viết tắt là Kỳ thi HSG) là kỳ thi chọn học sinh giỏi cấp Tỉnh, Thành phố dành cho học sinh bậc trung học phổ thông do Bộ Giáo dục và Đào tạo các Tỉnh và Thành phố tổ chức`,
    data: [
      { label: "Tất cả các chuyên đề", key: "Tatca" },
      { label: "Tính đơn điệu của hàm số", key: "Hamso_Dondieu" },
      { label: "Cực trị của hàm số", key: "Hamso_Cuctri" },
      { label: "Giá trị lớn nhất và nhỏ nhất của hàm số", key: "Hamso_Minmax" },
      { label: "Tiệm cận của đồ thị hàm số", key: "Hamso_Tiemcan" },
      { label: "Tương giao của đồ thị hàm số", key: "Hamso_Tuonggiao" },
      { label: "Tiếp tuyến của đồ thị hàm số", key: "Hamso_Tieptuyen" },
      {
        label: "Ứng dụng đạo hàm để giải bài toán thực tế",
        key: "Hamso_Toanthucte",
      },
      { label: "Vector và hệ tọa độ trong không gian", key: "Vector_Hetoado" },
      {
        label: "Ứng dụng vector để giải bài toán thực tế",
        key: "Vector_Toanthucte",
      },
      {
        label: "Khoảng biến thiên – Khoảng tứ phân vị của MSLGN",
        key: "Mausolieughepnhom_Khoangbienthien_Khoangtuphanvi",
      },
      {
        label: "Phương sai & độ lệch chuẩn của MSLGN",
        key: "Mausolieughepnhom_Phuongsai_Dolechchuan",
      },
      { label: "Nguyên hàm", key: "TichphanUngdung_Nguyeham" },
      { label: "Tích phân", key: "TichphanUngdung_Tichphan" },
      {
        label: "Ứng dụng tích phân (diện tích và thể tích)",
        key: "TichphanUngdung_Dientich_Thetich",
      },
      {
        label: "Ứng dụng tích phân giải bài toán thực tế",
        key: "TichphanUngdung_Toanthucte",
      },
      { label: "Hệ tọa độ trong Oxyz", key: "OXYZ_Hetoado" },
      {
        label: "Phương trình đường thẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhduongthang",
      },
      {
        label: "Phương trình mặt phẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhmatphang",
      },
      {
        label: "Phương trình mặt cầu trong Oxyz",
        key: "OXYZ_Phuongtrinhmatcau",
      },
      { label: "Cực trị trong Oxyz", key: "OXYZ_Cuctri" },
      {
        label: "Ứng dụng Oxyz để giải bài toán thực tế",
        key: "OXYZ_Toanthucte",
      },
      {
        label: "Xác suất có điều kiện",
        key: "Xacsuatcodieukien_Xacsuatcodieukien",
      },
      {
        label: "Công thức xác suất toàn phần và Bayes",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bayes",
      },
      {
        label: "Công thức xác suất Bernoulli",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bernoulli",
      },
      {
        label: "Giá trị lượng giác của góc lượng giác",
        key: "Luonggiac_Gocluonggiac",
      },
      { label: "Công thức lượng giác", key: "Luonggiac_Congthucluonggiac" },
      { label: "Hàm số lượng giác", key: "Luonggiac_Hamsoluonggiac" },
      {
        label: "Phương trình lượng giác",
        key: "Luonggiac_Phuongtrinhluonggiac",
      },
      {
        label: "Ứng dụng lượng giác để giải bài toán thực tế",
        key: "Luonggiac_Toanthucte",
      },
      {
        label: "Quy tắc cộng, Quy tắc nhân",
        key: "Xacsuat_Quytaccong_Quytacnhan",
      },
      {
        label: "Hoán vị - Tổ hợp - Chỉnh hợp",
        key: "Xacsuat_HoanVi_Tohop_ChinhHop",
      },
      {
        label: "Biến cố và định nghĩa cổ điển của xác suất",
        key: "Xacsuat_Bienco_Dinhnghiaxacsuat",
      },
      {
        label: "Biến cố hợp, biến cố giao, biến cố độc lập",
        key: "Xacsuat_Biencohop_Biencogiao_Biencodoclap",
      },
      { label: "Công thức cộng xác suất", key: "Xacsuat_Congthuccong" },
      {
        label: "Công thức nhân xác suất cho hai biến cố độc lập",
        key: "Xacsuat_Congthucnhan",
      },
      { label: "Nhị thức Newton", key: "Xacsuat_Nhithucnewton" },
      { label: "Dãy số", key: "Dayso_Dayso" },
      { label: "Cấp số nhân", key: "Dayso_Capsonhan" },
      { label: "Cấp sô cộng", key: "Dayso_Capsocong" },
      {
        label: "Ứng dụng dãy số để giải bài toán thực tế",
        key: "Dayso_Toanthucte",
      },
      { label: "Giới hạn của dãy số", key: "Gioihan_Gioihandayso" },
      { label: "Giới hạn của hàm số", key: "Gioihan_Gioihanhamso" },
      { label: "Hàm số liên tục", key: "Gioihan_Hamsolientuc" },
      {
        label: "Ứng dụng giới hạn để giải bài toán thực tế",
        key: "Gioihan_Toanthucte",
      },
      { label: "Góc trong không gian", key: "Hinhhockhonggian_Goc" },
      {
        label: "Khoảng cách trong không gian",
        key: "Hinhhockhonggian_Khoangcach",
      },
      { label: "Thể tích khối đa diện", key: "Hinhhockhonggian_Thetich" },
      { label: "Tỉ số thể tích", key: "Hinhhockhonggian_Tisothetich" },
      {
        label: "Tỉ số, thiết diện, quan hệ song song, quan hệ vuông góc",
        key: "Hinhhockhonggian_Tiso_Thietdien_Quanhesongsong_Quanhevuongoc",
      },
      { label: "Cực trị hình học không gian", key: "Hinhhockhonggian_Cuctri" },
      {
        label: "Ứng dụng hình học không gian để giải bài toán thực tế",
        key: "Hinhhockhonggian_Toanthucte",
      },
      {
        label: "Lũy thừa và hàm số lũy thừa trong mũ - logarit",
        key: "MuLogarit_Luythua_Hamsoluythua",
      },
      {
        label: "Phương trình, bất phương trình logarit",
        key: "MuLogarit_Phuongtrinh_Batphuongtrinh_Logarit",
      },
      {
        label: "Phương trình, bất phương trình mũ",
        key: "MuLogarit_Phuongtrinh_Batphuongtrinh_Mu",
      },
      {
        label: "Bài toán nghiệm nguyên trong trong mũ - logarit",
        key: "MuLogarit_Nghiemnguyen",
      },
      {
        label: "Bài toán chứa tham số trong trong mũ - logarit",
        key: "MuLogarit_Chuathamso",
      },
      { label: "Cực trị trong trong mũ - logarit", key: "MuLogarit_Minmax" },
      {
        label: "Ứng dụng mũ logarit để giải bài toán thực tế",
        key: "MuLogarit_Toanthucte",
      },
      { label: "Đạo hàm", key: "DaoHam" },
      {
        label: "Hình trụ, Hinh nón, Hình cầu",
        key: "TruNonCau_Tru_Non_Cau",
      },
      {
        label: "Bài toán thực tế liên quan đến hình trụ, nón, cầu",
        key: "TruNonCau_Toanthucte",
      },
      { label: "Tập hợp", key: "Taphop" },
      { label: "Mệnh đề", key: "Menhde" },
      { label: "Phương trình", key: "Phuongtrinh" },
      { label: "Hệ phương trình", key: "Hephuongtrinh" },
      { label: "Bất phương trình", key: "Batphuongtrinh" },
      { label: "Hệ bất phương trình", key: "HeBatphuongtrinh" },
      {
        label:
          "Bài toán thực tế liên quan đến bất phương trình và hệ bất phương trình",
        key: "Batphuongtrinh_HeBatphuongtrinh_Toanthucte",
      },
      { label: "Hệ thức lượng trong tam giác", key: "Hethucluongtrongtamgiac" },
      {
        label: "Bài toán thực tế liên quan đến Hệ thức lượng trong tam giác",
        key: "Hethucluongtrongtamgiac_Toanthucte",
      },
      { label: "Vector", key: "Vector" },
      { label: "Hàm số, đồ thị và ứng dụng", key: "Hamso_Dothi_Ungdung" },
      { label: "Phương trình đường thẳng", key: "Oxy_Phuongtrinhduongthang" },
      { label: "Phương trình đường tròn", key: "Oxy_Phuongtrinhduongtron" },
      { label: "Ba đường conic", key: "Oxy_Baduongconic" },
      { label: "Bất đẳng thức", key: "Batdangthuc" },
      { label: "Số học", key: "Sohoc" },
    ],
  },
  {
    id: 3,
    url: preview.globe,
    title: "Thực chiến Tổng hợp",
    type: "MASTER",
    description: `Phần này sẽ chọn ngẫu nhiên câu hỏi của các kì thi THPT, TSA, HSA, APT, HSG`,
    data: [
      { label: "Tất cả các chuyên đề", key: "Tatca" },
      { label: "Tính đơn điệu của hàm số", key: "Hamso_Dondieu" },
      { label: "Cực trị của hàm số", key: "Hamso_Cuctri" },
      { label: "Giá trị lớn nhất và nhỏ nhất của hàm số", key: "Hamso_Minmax" },
      { label: "Tiệm cận của đồ thị hàm số", key: "Hamso_Tiemcan" },
      { label: "Tương giao của đồ thị hàm số", key: "Hamso_Tuonggiao" },
      { label: "Tiếp tuyến của đồ thị hàm số", key: "Hamso_Tieptuyen" },
      {
        label: "Ứng dụng đạo hàm để giải bài toán thực tế",
        key: "Hamso_Toanthucte",
      },
      { label: "Vector và hệ tọa độ trong không gian", key: "Vector_Hetoado" },
      {
        label: "Ứng dụng vector để giải bài toán thực tế",
        key: "Vector_Toanthucte",
      },
      {
        label: "Khoảng biến thiên – Khoảng tứ phân vị của MSLGN",
        key: "Mausolieughepnhom_Khoangbienthien_Khoangtuphanvi",
      },
      {
        label: "Phương sai & độ lệch chuẩn của MSLGN",
        key: "Mausolieughepnhom_Phuongsai_Dolechchuan",
      },
      { label: "Nguyên hàm", key: "TichphanUngdung_Nguyeham" },
      { label: "Tích phân", key: "TichphanUngdung_Tichphan" },
      {
        label: "Ứng dụng tích phân (diện tích và thể tích)",
        key: "TichphanUngdung_Dientich_Thetich",
      },
      {
        label: "Ứng dụng tích phân giải bài toán thực tế",
        key: "TichphanUngdung_Toanthucte",
      },
      { label: "Hệ tọa độ trong Oxyz", key: "OXYZ_Hetoado" },
      {
        label: "Phương trình đường thẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhduongthang",
      },
      {
        label: "Phương trình mặt phẳng trong Oxyz",
        key: "OXYZ_Phuongtrinhmatphang",
      },
      {
        label: "Phương trình mặt cầu trong Oxyz",
        key: "OXYZ_Phuongtrinhmatcau",
      },
      { label: "Cực trị trong Oxyz", key: "OXYZ_Cuctri" },
      {
        label: "Ứng dụng Oxyz để giải bài toán thực tế",
        key: "OXYZ_Toanthucte",
      },
      {
        label: "Xác suất có điều kiện",
        key: "Xacsuatcodieukien_Xacsuatcodieukien",
      },
      {
        label: "Công thức xác suất toàn phần và Bayes",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bayes",
      },
      {
        label: "Công thức xác suất Bernoulli",
        key: "Xacsuatcodieukien_Xacsuattoanphan_Bernoulli",
      },
      {
        label: "Giá trị lượng giác của góc lượng giác",
        key: "Luonggiac_Gocluonggiac",
      },
      { label: "Công thức lượng giác", key: "Luonggiac_Congthucluonggiac" },
      { label: "Hàm số lượng giác", key: "Luonggiac_Hamsoluonggiac" },
      {
        label: "Phương trình lượng giác",
        key: "Luonggiac_Phuongtrinhluonggiac",
      },
      {
        label: "Ứng dụng lượng giác để giải bài toán thực tế",
        key: "Luonggiac_Toanthucte",
      },
      {
        label: "Quy tắc cộng, Quy tắc nhân",
        key: "Xacsuat_Quytaccong_Quytacnhan",
      },
      {
        label: "Hoán vị - Tổ hợp - Chỉnh hợp",
        key: "Xacsuat_HoanVi_Tohop_ChinhHop",
      },
      {
        label: "Biến cố và định nghĩa cổ điển của xác suất",
        key: "Xacsuat_Bienco_Dinhnghiaxacsuat",
      },
      {
        label: "Biến cố hợp, biến cố giao, biến cố độc lập",
        key: "Xacsuat_Biencohop_Biencogiao_Biencodoclap",
      },
      { label: "Công thức cộng xác suất", key: "Xacsuat_Congthuccong" },
      {
        label: "Công thức nhân xác suất cho hai biến cố độc lập",
        key: "Xacsuat_Congthucnhan",
      },
      { label: "Nhị thức Newton", key: "Xacsuat_Nhithucnewton" },
      { label: "Dãy số", key: "Dayso_Dayso" },
      { label: "Cấp số nhân", key: "Dayso_Capsonhan" },
      { label: "Cấp sô cộng", key: "Dayso_Capsocong" },
      {
        label: "Ứng dụng dãy số để giải bài toán thực tế",
        key: "Dayso_Toanthucte",
      },
      { label: "Giới hạn của dãy số", key: "Gioihan_Gioihandayso" },
      { label: "Giới hạn của hàm số", key: "Gioihan_Gioihanhamso" },
      { label: "Hàm số liên tục", key: "Gioihan_Hamsolientuc" },
      {
        label: "Ứng dụng giới hạn để giải bài toán thực tế",
        key: "Gioihan_Toanthucte",
      },
      { label: "Góc trong không gian", key: "Hinhhockhonggian_Goc" },
      {
        label: "Khoảng cách trong không gian",
        key: "Hinhhockhonggian_Khoangcach",
      },
      { label: "Thể tích khối đa diện", key: "Hinhhockhonggian_Thetich" },
      { label: "Tỉ số thể tích", key: "Hinhhockhonggian_Tisothetich" },
      {
        label: "Tỉ số, thiết diện, quan hệ song song, quan hệ vuông góc",
        key: "Hinhhockhonggian_Tiso_Thietdien_Quanhesongsong_Quanhevuongoc",
      },
      { label: "Cực trị hình học không gian", key: "Hinhhockhonggian_Cuctri" },
      {
        label: "Ứng dụng hình học không gian để giải bài toán thực tế",
        key: "Hinhhockhonggian_Toanthucte",
      },
      {
        label: "Lũy thừa và hàm số lũy thừa trong mũ - logarit",
        key: "MuLogarit_Luythua_Hamsoluythua",
      },
      {
        label: "Phương trình, bất phương trình logarit",
        key: "MuLogarit_Phuongtrinh_Batphuongtrinh_Logarit",
      },
      {
        label: "Phương trình, bất phương trình mũ",
        key: "MuLogarit_Phuongtrinh_Batphuongtrinh_Mu",
      },
      {
        label: "Bài toán nghiệm nguyên trong trong mũ - logarit",
        key: "MuLogarit_Nghiemnguyen",
      },
      {
        label: "Bài toán chứa tham số trong trong mũ - logarit",
        key: "MuLogarit_Chuathamso",
      },
      { label: "Cực trị trong trong mũ - logarit", key: "MuLogarit_Minmax" },
      {
        label: "Ứng dụng mũ logarit để giải bài toán thực tế",
        key: "MuLogarit_Toanthucte",
      },
      { label: "Đạo hàm", key: "DaoHam" },
      {
        label: "Hình trụ, Hinh nón, Hình cầu",
        key: "TruNonCau_Tru_Non_Cau",
      },
      {
        label: "Bài toán thực tế liên quan đến hình trụ, nón, cầu",
        key: "TruNonCau_Toanthucte",
      },
      { label: "Tập hợp", key: "Taphop" },
      { label: "Mệnh đề", key: "Menhde" },
      { label: "Phương trình", key: "Phuongtrinh" },
      { label: "Hệ phương trình", key: "Hephuongtrinh" },
      { label: "Bất phương trình", key: "Batphuongtrinh" },
      { label: "Hệ bất phương trình", key: "HeBatphuongtrinh" },
      {
        label:
          "Bài toán thực tế liên quan đến bất phương trình và hệ bất phương trình",
        key: "Batphuongtrinh_HeBatphuongtrinh_Toanthucte",
      },
      { label: "Hệ thức lượng trong tam giác", key: "Hethucluongtrongtamgiac" },
      {
        label: "Bài toán thực tế liên quan đến Hệ thức lượng trong tam giác",
        key: "Hethucluongtrongtamgiac_Toanthucte",
      },
      { label: "Vector", key: "Vector" },
      { label: "Hàm số, đồ thị và ứng dụng", key: "Hamso_Dothi_Ungdung" },
      { label: "Phương trình đường thẳng", key: "Oxy_Phuongtrinhduongthang" },
      { label: "Phương trình đường tròn", key: "Oxy_Phuongtrinhduongtron" },
      { label: "Ba đường conic", key: "Oxy_Baduongconic" },
      { label: "Bất đẳng thức", key: "Batdangthuc" },
      { label: "Số học", key: "Sohoc" },
    ],
  },
];

const options = ["Tất cả", "VDT", "VD", "VDC"];

export default function LinearCard() {
  const [isStartedQuiz, setIsStartedQuiz] = useState(false);
  const [id, setId] = useState(null);
  const [isCheckCountUserQuestionBank, setIsCheckCountUserQuestionBank] =
    useState(false);
  console.log(
    "🚀 ~ LinearCard ~ countUserQuestionBank:",
    isCheckCountUserQuestionBank
  );
  const [selectedValue, setSelectedValue] = useState({
    label: "Tất cả các chuyên đề",
    key: "Tất cả",
  });

  const fetchCountUserQuestionBank = async () => {
    try {
      const resUserQuestionBank = await checkCountUserQuestionBank();
      if (
        resUserQuestionBank &&
        resUserQuestionBank.data &&
        resUserQuestionBank.data.data
      ) {
        setIsCheckCountUserQuestionBank(resUserQuestionBank.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCountUserQuestionBank();
  }, []);

  const [selectedOptions, setSelectedOptions] = useState(["Tất cả"]);

  const handleSearchChange = (event, newValue) => {
    // console.log("🚀 ~ handleSearchChange ~ newValue:", newValue);
    setSelectedValue(newValue); // Lưu giá trị được chọn
  };

  const handleChangeSelected = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      // On autofill we get a stringified value
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div className="lg:grid lg:grid-cols-3 lg:gap-4 flex flex-wrap lg:px-2 px-[4rem]">
      {items.map((item, i) => {
        return (
          <>
            <Dialog
              transition={{
                type: "spring",
                bounce: 0.05,
                duration: 0.5,
              }}
              key={i}
            >
              <DialogTrigger
                style={{
                  borderRadius: "12px",
                }}
                className="mb-[30px] flex w-full flex-col overflow-hidden border  bg-gray-300 hover:bg-gray-200 "
              >
                <DialogImage
                  src={item.url}
                  alt=""
                  className=" h-64 w-full object-cover"
                />
                <div className="flex flex-grow flex-row items-end justify-between p-3">
                  <div>
                    <DialogTitle className="text-zinc-950 text-xl ">
                      {item.title}
                    </DialogTitle>
                  </div>
                  <button className="absolute bottom-2 right-2 p-2  bg-gray-400 hover:bg-gray-500 rounded-full ">
                    <Plus className="w-6 h-6" />
                  </button>
                </div>
              </DialogTrigger>
              {isStartedQuiz && id === i && isCheckCountUserQuestionBank ? (
                <QuizBox
                  setIsStartedQuiz={setIsStartedQuiz}
                  topic={selectedValue}
                  level={selectedOptions}
                  type={item.type}
                />
              ) : (
                <DialogContainer
                  className="fixed inset-0 flex items-center justify-center lg:p-10 py-[5rem]"
                  setIsStartedQuiz={setIsStartedQuiz}
                >
                  <DialogContent
                    style={{
                      borderRadius: "24px",
                    }}
                    setIsStartedQuiz={setIsStartedQuiz}
                    className="relative flex h-full mx-auto flex-col overflow-y-auto border bg-gray-300 hover:bg-gray-200  lg:w-[1200px] w-[90%] mb-5"
                  >
                    <DialogImage
                      src={item.url}
                      alt=""
                      className="h-full object-contain lg:w-[40%] mx-auto"
                    />
                    <div className="p-6">
                      <DialogTitle className="lg:text-5xl text-3xl text-zinc-950">
                        {item.title}
                      </DialogTitle>
                      <div className="flex lg:flex-row flex-col my-5">
                        <Autocomplete
                          disablePortal
                          options={item.data}
                          className="lg:mb-0 mb-5 lg:w-[30%]"
                          onChange={handleSearchChange}
                          renderInput={(params) => (
                            <TextField {...params} label="Chuyên Đề" />
                          )}
                        />
                        <FormControl className="lg:ml-5 ml-0 lg:w-[30%]">
                          <InputLabel id="multi-select-checkbox-label">
                            Mức độ câu hỏi
                          </InputLabel>
                          <Select
                            labelId="multi-select-checkbox-label"
                            id="multi-select-checkbox"
                            multiple
                            value={selectedOptions}
                            onChange={handleChangeSelected}
                            input={<OutlinedInput label="Select Options" />}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {options.map((option) => (
                              <MenuItem key={option} value={option}>
                                <Checkbox
                                  checked={selectedOptions.indexOf(option) > -1}
                                />
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <button
                          className="lg:ml-5 ml-0 lg:mt-0 mt-5 group cursor-pointer slide-anime px-5 py-3 rounded-full w-[180px]  bg-black text-white  flex justify-between items-center font-semibold "
                          onClick={() => {
                            if (!isCheckCountUserQuestionBank) {
                              toast.error(
                                "Bạn đã hết lượt thi. Vui lòng nâng cấp lên tài khoản vip"
                              );
                              return;
                            }
                            setIsStartedQuiz(true);
                            setId(i);
                          }}
                        >
                          Bắt đầu thi{" "}
                          <div className="group-hover:translate-x-2 transition-all">
                            <ChevronsRight />
                          </div>
                        </button>
                      </div>

                      <DialogDescription
                        disableLayoutAnimation
                        variants={{
                          initial: { opacity: 0, scale: 0.8, y: -40 },
                          animate: { opacity: 1, scale: 1, y: 0 },
                          exit: { opacity: 0, scale: 0.8, y: -50 },
                        }}
                      >
                        <p className="mt-2 text-zinc-500 ">
                          {item.description}
                        </p>
                      </DialogDescription>
                    </div>
                    <DialogClose
                      className="text-zinc-50  bg-gray-400 p-4 hover:bg-gray-500 rounded-full "
                      setIsStartedQuiz={setIsStartedQuiz}
                    />
                  </DialogContent>
                </DialogContainer>
              )}
            </Dialog>
          </>
        );
      })}
    </div>
  );
}
