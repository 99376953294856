import React, { useState } from "react";
import NavbarComponent from "./NavbarComponent";
import HeroWrapComponent from "./HelloWorldComponent";
import FooterComponent from "./FooterComponent";
import QuizBox from "./QuizBox";
import { useEffect } from "react";
import LinearCard from "./LinearCard";
import { handleQuestionBank } from "../services/QuestionBankService";
import { ArrowRight } from "lucide-react";

export default function PracticeExercisesComponent() {

  return (
    <>
      <div className="max-w-[1140px] m-auto">
        <NavbarComponent />
        <HeroWrapComponent />
      </div>
      <div className="max-w-[1140px] m-auto mt-[8rem] mb-[8rem]">
        <LinearCard/>
      </div>
      <FooterComponent />
    </>
  );
}
