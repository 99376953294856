import React from "react";

const ExamDetails = ({ examData, isStarted }) => {
  return (
    <div className="col-md-12 heading-section text-center">
      <h2 className="mb-4">{examData?.title}</h2>
      <h5 className="mb-4 signika">
        Thời gian: 21h đến 22h30 &#40;{examData?.time} phút&#41;
      </h5>
      <h5>Số lượng câu hỏi: {examData?.numberOfQuestions}</h5>
      {isStarted && (
        <div className="mt-7">
          {" "}
          <a
            href={examData?.url}
            target="_blank"
            rel="noreferrer"
            className="text-emerald-600 border-2 border-dashed border-gray-400 p-[10px]"
          >
            Link đề ở đây
          </a>
        </div>
      )}
    </div>
  );
};

export default ExamDetails;
