import React from "react";
import QuizIcon from "@mui/icons-material/Quiz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Tooltip } from "@mui/material";
import { checkCurrentTimeInRangeTest } from "../common/common";

const ExamActions = ({
  startTime,
  endTime,
  examResult,
  handleStartRetestExam,
  handleViewSelectedAnswerExam,
}) => {
  return (
    <div className="w-full flex justify-center md:flex-row flex-col mb-10 mt-5">
      {!checkCurrentTimeInRangeTest(startTime, endTime) && (
        <Tooltip
          title={`Số lần thi còn lại: ${examResult?.maximumNumberOfExams}`}
          placement="top"
        >
          <span className="md:m-0 mx-auto">
            <Button
              className="w-[179.91px] md:mr-2 mr-0 md:mb-0 mb-5"
              variant="contained"
              startIcon={<QuizIcon />}
              size="large"
              onClick={handleStartRetestExam}
              disabled={examResult?.maximumNumberOfExams <= 0}
            >
              Thi lại
            </Button>
          </span>
        </Tooltip>
      )}

      <span className="md:m-0 mx-auto">
        <Button
          variant="contained"
          startIcon={<VisibilityIcon />}
          className="w-[179.91px] md:ml-2 ml-0"
          size="large"
          onClick={handleViewSelectedAnswerExam}
        >
          Xem đáp án
        </Button>
      </span>
    </div>
  );
};

export default ExamActions;
