import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserInfoById } from "../services/UserService";
import { Tooltip } from "@mui/material";
// import { toLowerCaseNonAccentVietnamese } from "../common/nonAccentVietnamese";

const ExamCartComponent = (props) => {
  const { exam, loading } = props;
  const { origin } = window.location;

  const copyToClipboard = (origin, id, type) => {
    let text = "";
    if (type === "THPT") {
      text = `${origin}/exam-papers/detail/${id}`;
    }
    if (type === "TSA") {
      text = `${origin}/exam-papers/detail/${id}`;
    }
    if (type === "HSA") {
      text = `${origin}/exam-papers/detail/${id}`;
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        () => {
          toast.success("Copy link đề thành công");
        },
        (err) => {
          toast.error("Copy link đề thất bại");
          console.error("Failed to copy: ", err);
        }
      );
    } else {
      toast.error("Clipboard API not supported!");
    }
  };

  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserInfoById();
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          const { data } = response.data;
          setUser(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      }
    };

    fetchData();
  }, []);
  const handleClickExamTest = (id) => {
    if (loading) return "#";

    if (exam?.active || user?.role === 1) {
      if (exam?.type === "THPT") return `/exam-papers/detail/${id}`;
      if (exam?.type === "TSA") return `/exam-papers/detail/${id}`;
      if (exam?.type === "HSA") return `/exam-papers/detail/${id}`;
      return "#";
    }
    return "#";
  };
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength - 3) + "...";
    } else {
      return text;
    }
  }

  const handleDropheart = () => {};
  return (
    <Tooltip title={exam?.title} placement="top">
      <Card
        sx={{
          maxWidth: 375,
          cursor: "pointer",
          marginBottom: "30px",
          boxShadow:
            "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
        }}
        className="hover-card transform transition-transform duration-300 hover:scale-105"
      >
        <CardHeader
          sx={{
            "& .MuiCardHeader-title": {
              fontSize: "15px",
              fontWeight: "bold",
              fontFamily: "Potta One, system-ui !important",
              marginBottom: "5px",
            },
            "& .MuiCardHeader-subheader": {
              fontSize: "0.9rem !important",
              fontFamily: "Palatino Linotype, Arial, sans-serif !important",
              fontWeight: "600 !important",
              color: "#000000 !important",
            },
          }}
          title={truncateText(exam?.title, 30)}
          subheader={"Ngày tạo: " + exam?.createdAt}
        />
        <Link
          to={handleClickExamTest(exam?.id)}
          onClick={() => {
            if (!exam?.active && user?.role !== 1 && loading) {
              toast.error("Đề chưa được mở bởi Admin");
            }
          }}
        >
          {" "}
          <CardMedia
            component="img"
            image="/images/logo_n.png"
            alt="Paella dish"
            className="w-[60%] m-auto"
          />
          <CardContent className="p-0">
            <Typography variant="body2" color="text.secondary">
              <ul
                style={{ listStyleType: "circle" }}
                className="ml-[30px] font-palatino font-semibold text-black text-base text-[0.9rem]"
              >
                <div className="grid grid-cols-2 gap-y-2">
                  <li className="text-[0.85rem]">Môn học: {exam?.subject}</li>
                  <li className="text-[0.85rem]">Kì thi: {exam?.type}</li>
                  <li className="text-[0.85rem]">
                    Số câu hỏi: {exam?.numberOfQuestions}
                  </li>

                  <li className="text-[0.85rem]">
                    Trạng thái: <span className="text-red-800 font-bold">{exam?.active ? "Đã mở" : "Chưa mở"}</span>
                  </li>
                  <li className="text-[0.85rem]">
                    Thời gian: {exam?.time} phút
                  </li>
                </div>
              </ul>
            </Typography>
          </CardContent>
        </Link>
        <CardActions disableSpacing>
          <a href="#pi">
            <IconButton aria-label="add to favorites" onClick={handleDropheart}>
              <FavoriteIcon />
            </IconButton>
          </a>
          <div>
            {" "}
            <IconButton
              aria-label="share"
              onClick={() => copyToClipboard(origin, exam?.id, exam?.type)}
            >
              <ShareIcon />
            </IconButton>
          </div>

          <div style={{ textAlign: "right", width: "100%" }}>
            <span
              style={{
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.6)",
                marginRight: "10px",
              }}
            >
              <span style={{ color: "#17b978", fontWeight: "bold" }}>
                {exam.numberOfTest ? exam.numberOfTest : 0}
              </span>{" "}
              lượt thi
            </span>
            <span
              style={{
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.6)",
                marginRight: "10px",
              }}
            >
              <span style={{ color: "#e53935", fontWeight: "bold" }}>
                {" "}
                {exam.numberOfLikes ? exam.numberOfLikes : 0}
              </span>{" "}
              lượt thích
            </span>
          </div>
        </CardActions>
      </Card>
    </Tooltip>
  );
};
export default ExamCartComponent;
